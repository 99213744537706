import { createSlice } from "@reduxjs/toolkit";

const versionSlice = createSlice({
    name: "version",
    initialState: {
        currentVersion: localStorage.getItem("currentVersion") || "",
        newVersionAvailable: false,
    },
    reducers: {
        setCurrentVersion: (state, action) => {
            state.currentVersion = action.payload;
            localStorage.setItem("currentVersion", action.payload);
        },
        setNewVersionAvailable: (state, action) => {
            state.newVersionAvailable = action.payload;
        },
    },
});

export const { setCurrentVersion, setNewVersionAvailable } = versionSlice.actions;

export default versionSlice.reducer;
