import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Card, CardContent, Typography, CircularProgress, Button } from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import { fetchTemplateByIdThunk } from 'store/standardTextsSlice';
import {appendErotisiEditorState, validateErotisiText} from "store/userInfoSlice";

const TemplateDialog = ({  onClose, templateIds }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const erotisi = useSelector((state) => state.userInfoSlice.erotisi)
    const templateDialogOpen = useSelector((state) => state.knowledgeBasePageSlice.templateDialogOpen);

    useEffect(() => {
        if (templateDialogOpen && Array.isArray(templateIds) && templateIds.length > 0) {
            const fetchTemplates = async () => {
                setLoading(true);
                try {
                    const fetchedTemplates = await Promise.all(
                        templateIds.map((id) =>
                            dispatch(fetchTemplateByIdThunk(id))
                                .unwrap()
                                .catch((error) => {
                                    console.error(`Error fetching template ID ${id}:`, error);
                                    return null; // Return null for failed fetches
                                })
                        )
                    );
                    // Filter out null results and templates without an ID
                    const validTemplates = fetchedTemplates.filter((template) => template && template.id);
                    setTemplates(validTemplates);
                } catch (error) {
                    console.error("Error fetching templates:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchTemplates();
        }
    }, [templateDialogOpen]);

    const handleTransferAnswer = (templateText) => {
        const newHtmlContent = preprocessHtml(templateText);
        const combinedHtmlContent = erotisi
            ? `${erotisi}<br>${newHtmlContent}`
            : newHtmlContent;

        dispatch(validateErotisiText(combinedHtmlContent));
        dispatch(appendErotisiEditorState(combinedHtmlContent));
        onClose();
    };

    return (
        <Dialog open={templateDialogOpen} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Πρότυπα Κείμενα</span>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#003476',
                            color: '#fff',
                        }}
                        onClick={onClose}
                    >
                        Κλείσιμο
                    </Button>
                </div>
            </DialogTitle>

            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        {templates.map((template) => (
                            <Card
                                key={template.id}
                                style={{
                                    width: '100%',
                                    backgroundColor: '#f5f5f5',
                                    border: '1px solid #ddd',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                        {template.title}
                                    </Typography>
                                    <Typography variant="body2"  style={{ marginTop: '8px' }}>
                                        {template.templateText}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        style={{
                                            marginTop: '16px',
                                            backgroundColor: '#003476',
                                            color: '#fff',
                                        }}
                                        onClick={() => handleTransferAnswer(template.templateText)}
                                    >
                                        Μεταφορά απάντησης
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                )}

            </DialogContent>
        </Dialog>
    );
};

export default TemplateDialog;

// Helper function for HTML preprocessing
const preprocessHtml = (html) => {
    // Implement your preprocessing logic here
    return html.trim();
};
