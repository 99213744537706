import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
    fetchAllTemplates,
    fetchTemplateById,
    createTemplate,
    updateTemplate,
    deleteTemplate,
} from "app/Api Calls/AnswerTextTemplate";
import fetchThematicById from "app/Api Calls/GetThematicById";
import {fetchAllCarriers, fetchSubthematic, fetchThematicsByCarrierIdCall} from "app/Api Calls/CarriersThematicsCalls";

const initialState = {
    templates: {},
    thematics: {}, // Store thematics keyed by their IDs
    carriers: {}, // Store carriers keyed by their IDs
    loading: false,
    loadingByCarrierId: {},
    dialogLoading: false,
    subthematics: {},
    loadingByThematicId: {},
    error: null,
};

// Fetch all templates and their related thematics
export const fetchAllTextTemplates = createAsyncThunk(
    "standardTexts/fetchAllTextTemplates",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const templates = await fetchAllTemplates();
            const thematicsIds = [
                ...new Set(templates.flatMap((template) => template.thematicsId || [])),
            ];

            // Fetch all thematics in parallel
            const thematicsResponses = await Promise.all(
                thematicsIds.map((id) =>
                    fetchThematicById(id).catch((error) => {
                        console.error(`Failed to fetch thematic ${id}`, error);
                        return null;
                    })
                )
            );

            const thematics = thematicsResponses.filter(Boolean); // Remove failed fetches
            return { templates, thematics };
        } catch (error) {
            console.error("Error fetching all templates or thematics:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Fetch all carriers
export const fetchAllCarriersThunk = createAsyncThunk(
    "standardTexts/fetchAllCarriers",
    async (_, { rejectWithValue }) => {
        try {
            const carriers = await fetchAllCarriers();
            return carriers;
        } catch (error) {
            console.error("Error fetching all carriers:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Fetch thematic by ID
export const fetchThematicByIdThunk = createAsyncThunk(
    "standardTexts/fetchThematicById",
    async (thematicId, { rejectWithValue }) => {
        try {
            const response = await fetchThematicById(thematicId);
            return response;
        } catch (error) {
            console.error(`Error fetching thematic with ID ${thematicId}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchSubThematicByIdThunk = createAsyncThunk(
    "standardTexts/fetchSubThematicById",
    async (thematicId, { rejectWithValue }) => {
        try {
            const response = await fetchThematicById(thematicId);
            return response;
        } catch (error) {
            console.error(`Error fetching thematic with ID ${thematicId}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchSubthematicThunk = createAsyncThunk(
    "standardTexts/fetchSubthematic",
    async (parentId, { getState, rejectWithValue }) => {
        const state = getState().standardTexts;

        // Check if subthematics for this parentId already exist
        if (state.subthematics[parentId]) {
            console.log(`Subthematics for parentId ${parentId} already exist in state.`);
            return { parentId, subthematics: state.subthematics[parentId] };
        }

        try {
            console.log(`Fetching subthematics for parentId ${parentId}...`);
            const response = await fetchSubthematic(parentId);
            return { parentId, subthematics: response };
        } catch (error) {
            console.error(`Error fetching subthematic for parent ID ${parentId}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


// Create a new template
export const createTextTemplate = createAsyncThunk(
    "standardTexts/createTextTemplate",
    async (templateData, { rejectWithValue }) => {
        try {
            const response = await createTemplate(templateData);
            return response;
        } catch (error) {
            console.error("Error creating template:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Update an existing template
export const updateTextTemplate = createAsyncThunk(
    "standardTexts/updateTextTemplate",
    async (templateData, { rejectWithValue }) => {
        try {
            const response = await updateTemplate(templateData);
            return response;
        } catch (error) {
            console.error("Error updating template:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Delete a template by ID
export const deleteTextTemplate = createAsyncThunk(
    "standardTexts/deleteTextTemplate",
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteTemplate(id);
            return id; // Return the deleted template ID
        } catch (error) {
            console.error(`Error deleting template with ID ${id}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchThematicsByCarrierIdThunk = createAsyncThunk(
    "standardTexts/fetchThematicsByCarrierId",
    async ({ carrierId, fromDialog }, { getState, rejectWithValue }) => {
        const state = getState().standardTexts;
        // Avoid redundant fetch if data already exists and not called from dialog
        if (!fromDialog && Object.values(state.thematics).some((thematic) => thematic.carrierId === carrierId)) {
            return { carrierId, thematics: [], fromDialog };
        }
        try {
            const response = await fetchThematicsByCarrierIdCall(carrierId);
            return { carrierId, thematics: response, fromDialog };
        } catch (error) {
            console.error(`Error fetching thematics for carrier ID ${carrierId}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


// Fetch a single template by ID
export const fetchTemplateByIdThunk = createAsyncThunk(
    "standardTexts/fetchTemplateById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await fetchTemplateById(id);

            // Validate response structure
            if (!response || !response.id || typeof response.id !== "number") {
                throw new Error(`Invalid response structure for template ID ${id}`);
            }

            return response;
        } catch (error) {
            console.error(`Error fetching template with ID ${id}:`, error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);




const standardTextsSlice = createSlice({
    name: "standardTexts",
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTextTemplates.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllTextTemplates.fulfilled, (state, action) => {
                const { templates, thematics } = action.payload;
                templates.forEach((template) => {
                    state.templates[template.id] = template;
                });
                thematics.forEach((thematic) => {
                    state.thematics[thematic.id] = thematic;
                });
                state.loading = false;
            })
            .addCase(fetchAllTextTemplates.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch templates or thematics.";
            })

            // Fetch all carriers
            .addCase(fetchAllCarriersThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllCarriersThunk.fulfilled, (state, action) => {
                action.payload.forEach((carrier) => {
                    state.carriers[carrier.id] = carrier;
                });
                state.loading = false;
            })
            .addCase(fetchAllCarriersThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch carriers.";
            })

            // Fetch thematic
            .addCase(fetchThematicByIdThunk.fulfilled, (state, action) => {
                const thematic = action.payload;
                state.thematics[thematic.id] = thematic;
            })
            .addCase(fetchSubThematicByIdThunk.fulfilled, (state, action) => {
                const thematic = action.payload;
                state.subthematics[thematic.id] = thematic;
            })
            // Create template
            .addCase(createTextTemplate.fulfilled, (state, action) => {
                const newTemplate = action.payload;
                state.templates[newTemplate.id] = newTemplate;
            })

            // Update template
            .addCase(updateTextTemplate.fulfilled, (state, action) => {
                const updatedTemplate = action.payload;
                state.templates[updatedTemplate.id] = updatedTemplate;
            })

            // Delete template
            .addCase(deleteTextTemplate.fulfilled, (state, action) => {
                const id = action.payload;
                delete state.templates[id];
            })
            .addCase(fetchThematicsByCarrierIdThunk.pending, (state, action) => {
                const { carrierId, fromDialog } = action.meta.arg;
                if (fromDialog) {
                    state.dialogLoading = true;
                } else {
                    state.loadingByCarrierId[carrierId] = true;
                }
                state.error = null;
            })
            .addCase(fetchThematicsByCarrierIdThunk.fulfilled, (state, action) => {
                const { carrierId, thematics, fromDialog } = action.payload;
                thematics.forEach((thematic) => {
                    state.thematics[thematic.id] = { ...thematic, carrierId };
                });
                if (fromDialog) {
                    state.dialogLoading = false;
                } else {
                    state.loadingByCarrierId[carrierId] = false;
                }
            })
            .addCase(fetchThematicsByCarrierIdThunk.rejected, (state, action) => {
                const { carrierId, fromDialog } = action.meta.arg;
                if (fromDialog) {
                    state.dialogLoading = false;
                } else {
                    state.loadingByCarrierId[carrierId] = false;
                }
                state.error = action.payload || `Failed to fetch thematics for carrier ID ${carrierId}.`;
            })
            .addCase(fetchTemplateByIdThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTemplateByIdThunk.fulfilled, (state, action) => {
                const template = action.payload;
                state.templates[template.id] = template;
                state.loading = false;
            })
            .addCase(fetchTemplateByIdThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || `Failed to fetch template with ID.`;
            })

            .addCase(fetchSubthematicThunk.pending, (state, action) => {
                const parentId = action.meta.arg;
                state.loadingByThematicId[parentId] = true; // Set loading state for specific thematic
                state.error = null;
            })
            .addCase(fetchSubthematicThunk.fulfilled, (state, action) => {
                const { parentId, subthematics } = action.payload;

                // Only update if the parentId is not already present in state
                if (!state.subthematics[parentId]) {
                    state.subthematics[parentId] = subthematics; // Store subthematics by parent ID
                }
                state.loadingByThematicId[parentId] = false;
            })
            .addCase(fetchSubthematicThunk.rejected, (state, action) => {
                const parentId = action.meta.arg;
                state.loadingByThematicId[parentId] = false;
                state.error = action.payload || `Failed to fetch subthematics for parent ID ${parentId}.`;
            });


    },
});

// Memoized Selectors
const selectTemplates = (state) => state.standardTexts.templates;

export const selectAllTemplates = createSelector([selectTemplates], (templates) =>
    Object.values(templates)
);

const selectThematics = (state) => state.standardTexts.thematics;
const selectSubthematics = (state) => state.standardTexts.subthematics;


export const selectSubthematicsByThematicId = createSelector(
    [selectSubthematics, (_, thematicId) => thematicId],
    (subthematics, thematicId) => subthematics[thematicId] || []
);

export const selectAllThematics = createSelector([selectThematics], (thematics) =>
    Object.values(thematics)
);

const selectCarriers = (state) => state.standardTexts.carriers;

export const selectAllCarriers = createSelector([selectCarriers], (carriers) =>
    Object.values(carriers)
);

export const selectLoading = (state) => state.standardTexts.loading;

export const { clearError } = standardTextsSlice.actions;

export default standardTextsSlice.reducer;
