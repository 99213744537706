import { axiosApi } from "app/configs/axiosConfig";

const sortData = (data) => data.sort((a, b) => a.title.localeCompare(b.title, 'el'));


export const getActiveThematicsByParentId = async (parentId) => {
    try {
        const response = await axiosApi.get(`/thematic/byParentId/active/${parentId}`);

        // Sort the data by title in ascending order
        const sortedData = response.data.sort((a, b) => a.title.localeCompare(b.title));

        return sortedData;
    } catch (err) {
        console.error(err);
    }
};


export const getActiveThematicsByCarrierId = async (carrierId) => {
    try {
        const response = await axiosApi.get(`/thematic/byCarrierId/active/${carrierId}`);
        return sortData(response.data).filter((thematic) => thematic.parentId === null);
    } catch (err) {
        console.error(err);
    }
}

