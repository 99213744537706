import React from "react";
import {useSelector, useDispatch} from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {setFormSubmitted} from "store/ticketCreateSlice";

import {setActiveTab, setSelectedSubthematic, setTemplateDialogOpen} from "store/knowledgeBasePageSlice";
import UserInfo from "./UserInfo";

import {setCallerId} from "store/ticketCreateSlice";
import {isValidCallerId} from "app/main/Fields/ValidateFields";
import MandatoryFieldsList from "./MandatoryFieldsList";
import CustomTextField from "app/main/Reusable Components/InputCustom";
import TodayDate from "app/main/Reusable Components/Ticket Reusable/TodayDate";
import FlagsTicket from "app/main/Reusable Components/Ticket Reusable/FlagsTicket";
import CarrierSelector from "app/main/Reusable Components/Ticket Reusable/CarrierSelector";
import ThematicSelector from "app/main/Reusable Components/Ticket Reusable/ThematicSelector";
import SubThematicSelector from "app/main/Reusable Components/Ticket Reusable/SubthematicSelector";
import SubSubThematicSelector from "app/main/Reusable Components/Ticket Reusable/SubSubthematicSelector";
import WysiwigEditor from "app/main/Reusable Components/Ticket Reusable/WysiwigEditor";
import ResultsGnosiaki from "./ResultsGnosiaki";
import IstorikoTicket from "./IstorikoTicket";
import TicketStatus from "app/main/Reusable Components/Ticket Reusable/TicketStatus";
import SubmitWithResolution from "app/main/Reusable Components/Ticket Reusable/SubmitWithResolution";
import SearchGnosiaki from "./SearchGnosiaki";
import GeographySelector from "./GeographySelector";
import {CircularProgress, Typography} from "@mui/material";
import Announcements from "app/main/tickets/create-ticket/Announcments/Announcements";
import FollowUpButtonModal from "app/main/tickets/create-ticket/FollowUpButton";
import TemplateDialog from "app/main/tickets/create-ticket/TemplateDialog";


function KnowledgeBasePage() {
    const activeTab = useSelector(
        (state) => state.knowledgeBasePageSlice.activeTab
    );
    const dispatch = useDispatch();
    const callerId = useSelector((state) => state.ticketSlice.callerId);
    const isCarrierOPEKA = useSelector(state => state.ticketSlice.isCarrierOPEKA);
    const selectedCarrier = useSelector(state => state.knowledgeBasePageSlice.selectedCarrier);
    const isLoadingBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.isLoadingBeneficiary);
    const isSubmitingTicket = useSelector((state) => state.ticketSlice.isSubmitingTicket);
    const isLoadingRepresentative = useSelector((state) => state.representativeFetchSlice.isLoadingRepresentative);
    const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags);
    const isFollowUpActive = activeFlags.some(flag => flag.code === 200 && flag.name === "Follow up");
    const followupTicketId = useSelector((state) => state.historyTicketSlice.followupTicketId);
    const templateButtonVisible = useSelector((state) => state.knowledgeBasePageSlice.templateButtonVisible);
    const selectedSubThematic = useSelector(
        (state) => state.knowledgeBasePageSlice.selectedSubthematic
    );
    const handleTabSelect = (index) => {
        dispatch(setActiveTab(index));
    };

    const onFormSubmit = () => {
        dispatch(setFormSubmitted(true));
    };


    const handleTemplateDialogOpen = () => {
        dispatch(setTemplateDialogOpen(true));
    };

    const handleCloseDialog = () => {
        dispatch(setTemplateDialogOpen(false));
    };


    return (
        <div className="main-container-KnowledgeBase w-full  ">
            <div className="flex flex-col justify-start items-start  ">

                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{flex: '0.2'}}>
                        <Tabs
                            value={activeTab}
                            onChange={(event, newValue) => handleTabSelect(newValue)}
                        >
                            <Tab label="Πληροφορίες"/>
                            <Tab label="CRM"/>
                        </Tabs>
                    </div>
                    <div style={{
                        flex: '0.8',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                        {activeTab === 0 && (
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <MandatoryFieldsList/>
                                <div className="w-[300px]">
                                    <SubmitWithResolution
                                        onSubmit={onFormSubmit}
                                        ticketId={null}
                                        editMode={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>


                {activeTab === 0 && (
                    <Box className="w-full">
                        <form className="bg-[#eeeeee] rounded-lg w-full p-4 mr-10">
                            <div className="w-full flex justify-start items-center gap-[4rem] mb-4">
                                <div className="my-1.5 w-1/6">
                                    <CustomTextField
                                        name="callerId"
                                        label="Εισερχόμενο Τηλέφωνο"
                                        variant="outlined"
                                        fullWidth
                                        value={callerId}
                                        validator={isValidCallerId}
                                        action={setCallerId}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <TicketStatus/>
                                    <TodayDate/>
                                    {(followupTicketId?.id && followupTicketId?.uuid) && (
                                        <Typography style={{fontSize: '14px'}} variant="body1" component="span">
                                            <strong>Follow Up Id : </strong> {followupTicketId.uuid}
                                        </Typography>
                                    )}
                                </div>


                                <div className="flex justify-center items-center"
                                     style={{
                                         minWidth: '20%',
                                         maxWidth: '1000px',
                                         '@media (maxWidth: 14in)': {maxWidth: '600px'}
                                     }}>
                                    <FlagsTicket/>

                                </div>
                                {(isFollowUpActive && selectedCarrier) && <FollowUpButtonModal/>}
                                {selectedCarrier && <Announcements/>}
                            </div>


                            <div className="flex w-full gap-5">
                                <div className="w-1/2 h-full">
                                    <UserInfo enableFetchBeneficiary={true}/>
                                </div>

                                <div className="p-4 flex flex-col bg-white space-y-2 flex-1 w-1/2">
                                    <CarrierSelector isCreatePage={true}/>
                                    {(isCarrierOPEKA && selectedCarrier) && <GeographySelector isEditPage={false}/>}
                                    <ThematicSelector isCreatePage={true}/>
                                    <SubThematicSelector isCreatePage={true}/>
                                    <SubSubThematicSelector isCreatePage={true}/>
                                    {(templateButtonVisible && selectedSubThematic) && (
                                        <button
                                            type="button"
                                            style={{
                                                width: "100%",
                                                height: "40px",
                                                marginTop: "10px",
                                                backgroundColor: "#003476",
                                                color: "#fff",
                                                border: "none",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                            }}
                                            onClick={handleTemplateDialogOpen}
                                        >
                                            Πρότυπα Κείμενα
                                        </button>
                                    )}
                                    <TemplateDialog
                                        onClose={handleCloseDialog}
                                        templateIds={selectedSubThematic?.answerTextTemplateCollection?.map((template) => template.id) || []}
                                    />
                                    <div className="flex-grow"></div>
                                    {/* Spacer */}
                                    <SearchGnosiaki/>
                                </div>

                            </div>


                            <div className="flex flex-row w-full mt-2 gap-1 ">
                                <WysiwigEditor/>
                                <div className=" pl-4 pt-4 pb-4 flex flex-col  space-y-2 flex-1 w-1/2">
                                    <ResultsGnosiaki/>
                                </div>
                            </div>
                        </form>
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box className="w-full">
                        <IstorikoTicket/>
                    </Box>
                )}
            </div>
            {(isLoadingBeneficiary || isSubmitingTicket || isLoadingRepresentative) && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <div style={{
                        padding: '20px',
                        borderRadius: '8px',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}>
                        <p style={{marginBottom: '20px', fontSize: '18px', fontWeight: '500', color: '#333'}}>
                            {isSubmitingTicket ? 'Δημιουργία Ticket' : (isLoadingBeneficiary ? 'Αναζήτηση για Δικαιούχο' : (isLoadingRepresentative ? 'Αναζήτηση για Αντιπρόσωπο' : ''))}
                        </p>
                        <CircularProgress/>
                    </div>
                </div>
            )}

        </div>
    );
}

export default KnowledgeBasePage;
