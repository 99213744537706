import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentVersion, setNewVersionAvailable } from "store/versionSlice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { jwtService } from "app/auth/jwtService";
import { detectIncognito } from "detect-incognito";

const VersionChecker = () => {
    const dispatch = useDispatch();
    const currentVersion = useSelector((state) => state.versionSlice.currentVersion);
    const newVersionAvailable = useSelector((state) => state.versionSlice.newVersionAvailable);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isIncognito, setIsIncognito] = useState(null); // Start with null to indicate unknown state
    const accessToken = jwtService.getAccessToken();

    useEffect(() => {
        detectIncognito().then((result) => {
            setIsIncognito(result.isPrivate);
        });
    }, []);

    useEffect(() => {
        if (isIncognito === null) return;

        if (isIncognito) {
            silentVersionCheck();
        } else {
            startVersionCheckWithPolling();
        }
    }, [isIncognito]);

    const isTimestampWithinAWeek = (timestamp) => {
        const currentDate = new Date();
        const versionDate = new Date(timestamp);
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;

        return currentDate - versionDate <= oneWeekInMs;
    };

    const silentVersionCheck = async () => {
        try {
            const response = await fetch(`/version.json?timestamp=${new Date().getTime()}`);
            const data = await response.json();

            if (isTimestampWithinAWeek(data.timestamp)) {
                dispatch(setCurrentVersion(data.version)); // Set the version silently
            }
        } catch (error) {
            // Handle error if needed
        }
    };

    const startVersionCheckWithPolling = () => {
        checkForNewVersion();

        const intervalId = setInterval(() => {
            checkForNewVersion();
        }, 60000); // Poll every minute

        return () => {
            clearInterval(intervalId);
        };
    };

    const checkForNewVersion = async () => {
        try {
            const response = await fetch(`/version.json?timestamp=${new Date().getTime()}`);
            const data = await response.json();

            if (!isTimestampWithinAWeek(data.timestamp)) {
                // Skip if the timestamp is over a week old
                return;
            }

            if (accessToken) {
                if (!currentVersion) {
                    // First-time version set, don't show dialog
                    dispatch(setCurrentVersion(data.version));
                } else if (data.version !== currentVersion) {
                    // Show dialog for new version
                    dispatch(setCurrentVersion(data.version));
                    setDialogOpen(true);
                    dispatch(setNewVersionAvailable(true));
                }
            }
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (newVersionAvailable && !isIncognito) {
            setDialogOpen(true);
        }
    }, [newVersionAvailable, isIncognito]);

    if (isIncognito === null) return null; // Avoid rendering anything until incognito detection is complete

    if (isIncognito) return null; // Avoid rendering dialog in incognito mode

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="new-version-dialog"
        >
            <DialogTitle id="new-version-dialog">Νέα Έκδοση Διαθέσιμη</DialogTitle>
            <DialogContent>
                <p>
                    Μια νέα έκδοση της εφαρμογής είναι διαθέσιμη. Ανανεώστε τη σελίδα για να
                    λάβετε τις τελευταίες ενημερώσεις ή κλείστε για να συνεχίσετε τη δουλειά σας (Επανεμφάνιση σε 1 λεπτό).
                </p>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleRefresh}
                    style={{
                        backgroundColor: "#1976d2",
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        boxShadow: "none",
                        textTransform: "uppercase",
                    }}
                >
                    Ανανέωση Τώρα
                </Button>
                <Button
                    onClick={handleCloseDialog}
                    style={{
                        backgroundColor: "#f5f5f5",
                        color: "#1976d2",
                        fontWeight: "bold",
                        border: "1px solid #1976d2",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        textTransform: "uppercase",
                    }}
                >
                    Κλείσιμο
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VersionChecker;
