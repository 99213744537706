import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllCarriersThunk,
    fetchThematicsByCarrierIdThunk,
    fetchSubthematicThunk,
    fetchTemplateByIdThunk,
    selectAllCarriers,
    selectAllThematics,
    selectSubthematicsByParentId,
    selectAllTemplates,
    createTextTemplate,
    updateTextTemplate,
    deleteTextTemplate,
} from "store/standardTextsSlice";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {showMessage} from "store/messageSlice";

const StandardTextPage = () => {
    const dispatch = useDispatch();
    const carriers = useSelector(selectAllCarriers);
    const thematics = useSelector(selectAllThematics);
    const templates = useSelector(selectAllTemplates);
    const subthematics = useSelector((state) => state.standardTexts.subthematics);
    const loadingCarriers = useSelector((state) => state.standardTexts.isLoadingCarriers);
    const loadingThematicsByCarrier = useSelector((state) => state.standardTexts.loadingByCarrierId);
    const loadingSubthematics = useSelector((state) => state.standardTexts.loadingByThematicId);
    const loadingTemplates = useSelector((state) => state.standardTexts.loading);

    const [expandedCarrierId, setExpandedCarrierId] = useState(null);
    const [expandedThematicId, setExpandedThematicId] = useState(null);
    const [expandedSubthematicId, setExpandedSubthematicId] = useState(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [selectedTemplateToDelete, setSelectedTemplateToDelete] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [templateFormData, setTemplateFormData] = useState({
        title: "",
        templateText: "",
        carrierId: "",
        thematicId: "",
        subthematicsId: [],
    });

    useEffect(() => {
        dispatch(fetchAllCarriersThunk());
    }, [dispatch]);

    const handleCarrierExpand = (carrierId) => {
        if (carrierId === expandedCarrierId) {
            setExpandedCarrierId(null);
        } else {
            setExpandedCarrierId(carrierId);
            setExpandedThematicId(null);
            setExpandedSubthematicId(null);
            dispatch(fetchThematicsByCarrierIdThunk({carrierId, fromDialog: false}));
        }
    };

    const handleThematicExpand = (thematicId) => {
        if (thematicId === expandedThematicId) {
            setExpandedThematicId(null);
        } else {
            setExpandedThematicId(thematicId);
            setExpandedSubthematicId(null);
            dispatch(fetchSubthematicThunk(thematicId));
        }
    };

    const handleSubthematicExpand = (subthematic) => {
        if (subthematic.id === expandedSubthematicId) {
            setExpandedSubthematicId(null);
        } else {
            setExpandedSubthematicId(subthematic.id);

            // Fetch templates only if answerTextTemplateCollection is not empty
            if (subthematic.answerTextTemplateCollection.length > 0) {
                subthematic.answerTextTemplateCollection.forEach((template) => {
                    dispatch(fetchTemplateByIdThunk(template.id));
                });
            }
        }
    };


    const handleOpenDialog = (template) => {
        if (template) {
            setSelectedTemplate(template);

            // Extract subthematic IDs from the template
            const {thematicsId: subthematicsId} = template;

            // Find the thematic from the subthematic parent ID
            const thematic = thematics.find((thematic) =>
                subthematicsId.some((subId) =>
                    subthematics[thematic.id]?.some((sub) => sub.id === subId)
                )
            );

            const carrierId = thematic?.carrierId || "";

            setTemplateFormData({
                title: template.title || "",
                templateText: template.templateText || "",
                carrierId,
                thematicId: thematic?.id || "",
                subthematicsId,
            });

            // Fetch related data for dropdowns
            if (carrierId) {
                dispatch(fetchThematicsByCarrierIdThunk({carrierId, fromDialog: true}));
            }
            if (thematic?.id) {
                dispatch(fetchSubthematicThunk(thematic.id));
            }
        } else {
            setSelectedTemplate(null);
            setTemplateFormData({
                title: "",
                templateText: "",
                carrierId: "",
                thematicId: "",
                subthematicsId: [],
            });
        }
        setIsDialogOpen(true);
    };


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedTemplate(null);
        setTemplateFormData({
            title: "",
            templateText: "",
            carrierId: "",
            thematicId: "",
            subthematicsId: [],
        });
    };

    const canSaveTemplate = () => {
        const {title, templateText, thematicId} = templateFormData;
        return title.trim() !== "" && templateText.trim() !== "" && thematicId !== "";
    };

    const handleSaveTemplate = async () => {
        const {title, templateText, subthematicsId} = templateFormData;
        const thematicsId = subthematicsId; // Only include subthematicsId as thematicsId
        const dataToSave = {title, templateText, thematicsId}; // Exclude thematicId

        try {
            if (selectedTemplate) {
                await dispatch(updateTextTemplate({id: selectedTemplate.id, ...dataToSave})).unwrap();
                dispatch(
                    showMessage({
                        message: "Το πρότυπο ενημερώθηκε με επιτυχία!",
                        variant: "success",
                    })
                );
            } else {
                await dispatch(createTextTemplate(dataToSave)).unwrap();
                dispatch(
                    showMessage({
                        message: "Το πρότυπο δημιουργήθηκε με επιτυχία!",
                        variant: "success",
                    })
                );
            }
            handleCloseDialog();
        } catch (error) {
            dispatch(
                showMessage({
                    message: "Αποτυχία αποθήκευσης προτύπου!",
                    variant: "error",
                })
            );
        }
    };


    const handleDeleteTemplate = async () => {
        try {
            await dispatch(deleteTextTemplate(selectedTemplateToDelete)).unwrap();
            dispatch(showMessage({message: "Το πρότυπο διαγράφηκε με επιτυχία!", variant: "success"}));
            setOpenDeleteDialog(false);
        } catch (error) {
            dispatch(showMessage({message: "Αποτυχία διαγραφής προτύπου!", variant: "error"}));
        }
    };

    const renderTemplates = (subthematicId) => {
        const subthematicTemplates = templates.filter((template) =>
            Array.isArray(template.thematicsId) && template.thematicsId.includes(subthematicId)
        );

        return subthematicTemplates.length > 0 ? (
            subthematicTemplates.map((template) => (
                <div
                    key={template.id}
                    style={{
                        marginBottom: "10px",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                    }}
                >
                    <Typography variant="h6">{template.title}</Typography>
                    <Typography variant="body1" style={{ fontSize: "0.875rem" }}>{template.templateText}</Typography>

                    <Button
                        variant="contained"
                        onClick={() => handleOpenDialog(template)}
                        sx={{
                            marginRight: "10px",
                            backgroundColor: "#003476 !important",
                            color: "#fff",
                        }}
                    >
                        Επεξεργασία
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSelectedTemplateToDelete(template.id);
                            setOpenDeleteDialog(true);
                        }}                        sx={{
                            backgroundColor: "#d32f2f !important",
                            color: "#fff",
                        }}
                    >
                        Διαγραφή
                    </Button>

                </div>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">
                Δεν υπάρχουν πρότυπα
            </Typography>
        );
    };


    return (
        <div style={{padding: "20px"}}>
            <Typography variant="h4" gutterBottom>
                Πρότυπα Κείμενα
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: "#003476 !important",
                    color: "#fff",
                    marginBottom: "20px",
                }}
                onClick={() => handleOpenDialog(null)}
            >
                Δημιουργία Νέου Template
            </Button>

            {loadingCarriers ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                    <CircularProgress />
                </div>
            ) : (
                carriers.map((carrier) => (
                    <Accordion
                        key={carrier.id}
                        expanded={expandedCarrierId === carrier.id}
                        onChange={() => handleCarrierExpand(carrier.id)}
                    >
                        <AccordionSummary sx={{backgroundColor: "#C2E0FF"}} expandIcon={<ExpandMoreIcon/>}>
                            <Typography>{carrier.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {loadingThematicsByCarrier[carrier.id] ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                thematics
                                    .filter((thematic) => thematic.carrierId === carrier.id)
                                    .map((thematic) => (
                                        <Accordion
                                            key={thematic.id}
                                            expanded={expandedThematicId === thematic.id}
                                            onChange={() => handleThematicExpand(thematic.id)}
                                        >
                                            <AccordionSummary sx={{backgroundColor: "#C2E0FF"}} expandIcon={<ExpandMoreIcon/>}>
                                                <Typography>{thematic.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {loadingSubthematics[thematic.id] ? (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    subthematics[thematic.id]?.map((subthematic) => (
                                                        <Accordion
                                                            key={subthematic.id}
                                                            expanded={expandedSubthematicId === subthematic.id}
                                                            onChange={() => handleSubthematicExpand(subthematic)}
                                                        >
                                                            <AccordionSummary sx={{backgroundColor: "#C2E0FF"}} expandIcon={<ExpandMoreIcon/>}>
                                                                <Typography>{subthematic.title}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {renderTemplates(subthematic.id)}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))
            )}

            {/* Dialog for Template Creation/Editing */}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>{selectedTemplate ? "Επεξεργασία Template" : "Δημιουργία Template"}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="title"
                        label="Τίτλος"
                        fullWidth
                        margin="normal"
                        value={templateFormData.title}
                        onChange={(e) => setTemplateFormData({...templateFormData, title: e.target.value})}
                    />
                    <TextField
                        name="templateText"
                        label="Πρότυπο Κείμενο"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={templateFormData.templateText}
                        onChange={(e) => setTemplateFormData({...templateFormData, templateText: e.target.value})}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="carrier-label">Φορέας</InputLabel>
                        <Select
                            labelId="carrier-label"
                            value={templateFormData.carrierId}
                            onChange={(e) => {
                                const carrierId = e.target.value;
                                setTemplateFormData({
                                    ...templateFormData,
                                    carrierId,
                                    thematicId: "",
                                    subthematicsId: []
                                });
                                dispatch(fetchThematicsByCarrierIdThunk({carrierId, fromDialog: true}));
                            }}
                        >
                            <MenuItem value="">
                                <em>Κανένας</em>
                            </MenuItem>
                            {carriers.map((carrier) => (
                                <MenuItem key={carrier.id} value={carrier.id}>
                                    {carrier.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {templateFormData.carrierId && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="thematic-label">Θεματικές Ενότητες</InputLabel>
                            <Select
                                labelId="thematic-label"
                                value={templateFormData.thematicId}
                                onChange={(e) => {
                                    const thematicId = e.target.value;
                                    setTemplateFormData({...templateFormData, thematicId, subthematicsId: []});
                                    dispatch(fetchSubthematicThunk(thematicId));
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                            overflow: "auto",
                                            marginTop: 8, // Adds space below the select
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>Κανένας</em>
                                </MenuItem>
                                {thematics
                                    .filter((thematic) => thematic.carrierId === templateFormData.carrierId)
                                    .map((thematic) => (
                                        <MenuItem key={thematic.id} value={thematic.id}>
                                            {thematic.title}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    {templateFormData.thematicId && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="subthematics-label">Θέματα</InputLabel>
                            <Select
                                labelId="subthematics-label"
                                multiple
                                value={templateFormData.subthematicsId}
                                onChange={(e) =>
                                    setTemplateFormData({
                                        ...templateFormData,
                                        subthematicsId: [...new Set(e.target.value)],
                                    })
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                            overflow: "auto",
                                            marginTop: 8,
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                }}
                            >
                                {subthematics[templateFormData.thematicId]?.map((subthematic) => (
                                    <MenuItem key={subthematic.id} value={subthematic.id}>
                                        {subthematic.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSaveTemplate}
                        style={{backgroundColor: canSaveTemplate() ? "#003476" : "#ccc", color: "#fff"}}
                        disabled={!canSaveTemplate()}
                    >
                        Αποθήκευση
                    </Button>
                    <Button
                        onClick={handleCloseDialog}
                        style={{backgroundColor: "#d32f2f", color: "#fff"}}
                    >
                        Ακύρωση
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Deletion Confirmation */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Είστε σίγουροι ότι θέλετε να διαγράψετε το πρότυπο κείμενο;</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}
                            style={{backgroundColor: "#d32f2f", color: "#fff"}}>
                        Ακύρωση
                    </Button>
                    <Button
                        onClick={handleDeleteTemplate}
                        style={{backgroundColor: "#003476", color: "#fff"}}
                    >
                        Διαγραφή
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default StandardTextPage;

