import { axiosApi } from "app/configs/axiosConfig";

// Fetch all templates
export const fetchAllTemplates = async () => {
    try {
        const response = await axiosApi.get('/answer-text-template');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchTemplateById = async (id) => {
    try {
        const response = await axiosApi.get(`/answer-text-template/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Create a new template
export const createTemplate = async (templateData) => {
    try {
        const response = await axiosApi.post('/answer-text-template', templateData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Update an existing template
export const updateTemplate = async (templateData) => {
    try {
        const response = await axiosApi.put('/answer-text-template', templateData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Delete a template by ID
export const deleteTemplate = async (id) => {
    try {
        const response = await axiosApi.delete(`/answer-text-template/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
