// store.js
import { combineReducers } from '@reduxjs/toolkit';
import userSlice, { logoutUser } from './userSlice';
import messageSlice from './messageSlice';
import dialogSlice from './dialogSlice';
import ticketFetchSlice from './ticketFetchSlice';
import ticketCreateSlice from './ticketCreateSlice';
import knowledgeBasePageSlice from './knowledgeBasePageSlice';
import resolutionsSlice from './resolutionsSlice';
import beneficiaryFetchSlice from './beneficiaryFetchSlice';
import editTicketSlice from './editTicketSlice';
import historyTicketSlice from './historyTicketSlice';
import userInfoSlice from './userInfoSlice';
import teamsSlice from './teamsSlice';
import gnosiakiCrudSlice from './gnosiakiCrudSlice';
import userAssignmentSlice from './userAssignmentSlice';
import representativeFetchSlice from "store/representaticeFetchSlice";
import standardTextsSlice from "store/standardTextsSlice";
import versionSlice from "store/versionSlice";

const appReducer = combineReducers({
    user: userSlice,
    message: messageSlice,
    dialog: dialogSlice,
    ticketFetchSlice: ticketFetchSlice ,
    ticketSlice: ticketCreateSlice,
    knowledgeBasePageSlice: knowledgeBasePageSlice,
    resolutionsSlice: resolutionsSlice,
    beneficiaryFetchSlice: beneficiaryFetchSlice,
    editTicketSlice: editTicketSlice,
    historyTicketSlice: historyTicketSlice,
    userInfoSlice: userInfoSlice,
    teamsSlice: teamsSlice ,
    gnosiakiCrudSlice: gnosiakiCrudSlice,
    standardTexts: standardTextsSlice,
    userAssignmentSlice: userAssignmentSlice,
    representativeFetchSlice: representativeFetchSlice,
    versionSlice: versionSlice


});
const rootReducer = (state, action) => {
    if (action.type === logoutUser.type) {
        const { versionSlice, ...restState } = state;
        state = { versionSlice }; // Keep versionSlice intact and reset others to initial state
    }

    return appReducer(state, action);
};

export default rootReducer;
  

