import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataFromSubthematic,
  fetchQuestionsAndAnswersByThematicId, setCurrentPage,
  setResults,
  setSelectedSubSubthematic,
  setSelectedSubthematic, setSubSubthematics, setSubthematics, setTemplateButtonVisible,
} from 'store/knowledgeBasePageSlice';
import CategoriesDropdown from '../CategoriesDropdown';
import settingsConfig from "app/configs/settingConfig";

function SubThematicSelector({isCreatePage}) {
  const dispatch = useDispatch();
  const selectedThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedThematic);
  const subthematics = useSelector((state) => state.knowledgeBasePageSlice.subthematics);
  const subSubthematics = useSelector((state) => state.knowledgeBasePageSlice.subSubthematics);

  const isLoadingSubThematics = useSelector(
    (state) => state.knowledgeBasePageSlice.isLoadingSubThematics
  );
  const selectedSubthematic = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedSubthematic
  );
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const currentUserRole = useSelector((state) => state.user.roleName);

  useEffect(() => {
    if (selectedThematic) {
      dispatch(fetchDataFromSubthematic({isCreatePage, parentId: selectedThematic.value }));
    } else {
      dispatch(setSelectedSubthematic(null));
      dispatch(setResults([]));
      dispatch(setSubthematics([]));
      dispatch(setSubSubthematics([]));
      dispatch(setTemplateButtonVisible(false));
    }
  }, [selectedThematic, dispatch]);
  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      dispatch(setSelectedSubthematic(null));
      dispatch(setSelectedSubSubthematic(null));
      dispatch(setResults([]));
    } else if (subSubthematics.length === 0 && selectedOption) {
      dispatch(setSelectedSubthematic(selectedOption));
      dispatch(setCurrentPage(0));
      dispatch(fetchQuestionsAndAnswersByThematicId(selectedOption.value));
      dispatch(setTemplateButtonVisible(!!selectedOption?.answerTextTemplateCollection?.length));
    } else {
      dispatch(setSelectedSubthematic(selectedOption));
      dispatch(setTemplateButtonVisible(!!selectedOption?.answerTextTemplateCollection?.length));
      dispatch(setResults([]));

    }
  };

  const isTeamLeaderOrSupervisor = useMemo(() => {
    return settingsConfig.roles.teamLeader.includes(currentUserRole) ||
        settingsConfig.roles.supervisor.some(role => currentUserRole?.includes(role));
  }, [currentUserRole]);

  // Determine if the dropdown should be disabled
  const isDropdownDisabled = useMemo(() => {
    // Always enable the dropdown when isCreatePage is true
    if (isCreatePage) {
      return false;
    }
    // Disable the dropdown if the active status is 5 or if the user is not a team leader or supervisor
    return (activeStatus && activeStatus.id === 5) || !isTeamLeaderOrSupervisor;
  }, [isCreatePage, activeStatus, isTeamLeaderOrSupervisor]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedThematic && (
        <CategoriesDropdown
          options={subthematics.map((subThematic) => ({
            value: subThematic.id,
            label: subThematic.title,
            id: subThematic.id,
            answerTextTemplateCollection: subThematic?.answerTextTemplateCollection,
          }))}
          isDisabled={isDropdownDisabled}
          value={selectedSubthematic}
          onChange={handleChange}
          placeholder="Θέμα"
          backgroundColor="white"
          isLoading={isLoadingSubThematics}
        />
      )}
    </>
  );
}

export default SubThematicSelector;
